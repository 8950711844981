.logo {
  i {
    &.icon-bve,
    &.bionisys {
      &::before {
        @apply text-black;
      }
    }
  }
}
