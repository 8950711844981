input {
  border-radius: 0; // Fixes the iOS Input Border Rounding
  :focus {
    outline: 0; // remove blue browser border
  }
}

button:focus {
  outline: 0; // remove blue browser border
}

// Form CSS
form[name*='dynamic_form'] {
  @apply flex flex-wrap justify-start w-full;
  > div {
    @apply flex flex-wrap -mx-2 overflow-hidden;
  }

  .form-item {
    @apply my-2 px-2 overflow-hidden mt-4 relative;
    @media (min-width: $bp_md) {
      @apply mt-8;
    }

    &:first-child {
      @apply mt-0;
    }

    label p {
      @apply text-xs text-gray my-2;
    }

    // Different form sizes

    &.form-width-full {
      @apply w-full;
    }

    &.form-width-half {
      width: 100%;
      @media (min-width: $bp_md) {
        width: 50%;
      }
    }

    &.form-width-one-quarter {
      width: 100%;
      @media (min-width: $bp_md) {
        width: 25%;
      }
    }

    &.form-width-three-quarters {
      width: 100%;
      @media (min-width: $bp_md) {
        width: 75%;
      }
    }

    &.form-width-one-third {
      width: 100%;
      @media (min-width: $bp_md) {
        width: 34%;
      }
    }

    &.form-width-two-thirds {
      width: 100%;
      @media (min-width: $bp_md) {
        width: 66%;
      }
    }

    &.form-width-one-sixth {
      width: 100%;
      @media (min-width: $bp_md) {
        width: 16.6%;
      }
    }

    &.form-width-five-sixths {
      width: 100%;
      @media (min-width: $bp_md) {
        width: 83%;
      }
    }

    // Default Input Styles
    input[type='text'],
    input[type='email'],
    input[type='tel'],
    select,
    textarea {
      background-color: transparent;
      @apply border-b-2 border-black px-2 py-3 w-full;
      transition: background-color .4s ease-in-out;

      &:focus, &:hover {
        @apply bg-white;
      }

      &::placeholder {
        @apply text-gray-light;
      }
    }

    label.required {
      p {
        @apply inline-block pr-2 relative;
        &::after {
          content: '*';
          color: #ff0000;
          @apply absolute right-0;
        }
      }
    }

    textarea {
      min-height: 20vh;
      @media (min-width: $bp_md) {
        min-height: 10vh;
      }
    }

    // Checkboxes
    label[for*='_checkbox'] {
      @apply block pl-8;
      width: 60%;

      + input[type='checkbox'] {
        @apply absolute left-0 ml-2 top-0 mt-3;
      }
    }

    button[type='submit'] {
      @apply text-lg underline relative pl-10;
      &::before {
        @include iconfont($icon-arrow-right);
        @apply mr-4 absolute left-0;
      }

      &:hover {
        @apply text-brand;
      }
    }
  }

  .form-field-email_address {
    height: 0;
    margin: 0;
    visibility: hidden;
  }
}
