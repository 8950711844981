@mixin iconfont($value: '') {
  content: $value;
  font-family: 'mackicons';
  speak: none;
  font-style: normal;
  font-variant: normal;
  text-decoration: none;
  text-transform: none;
  z-index: inherit;
  text-rendering: optimizeLegibility;
}
