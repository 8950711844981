.pswpgallery {
  a {
    position: relative;
    overflow: hidden;
    display: block;

    &::before {
      @media (min-width: $bp_lg) {
        transition: background-color .3s ease-in-out;
      }
      background-color: rgba(0, 0, 0, 0);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: ' ';
      z-index: 1;
    }

    &::after {
      @include iconfont($icon-search);
      @apply text-white text-center absolute text-4xl m-auto right-0 left-0 top-0 bottom-0;
      width: 3rem;
      height: 3rem;
      opacity: 0;
      transform: scale(.75);
      z-index: 2;
      @media (min-width: $bp_lg) {
        transition: opacity .3s, transform .3s ease-in-out;
      }
    }

    &:hover {
      &::before {
        @media (min-width: $bp_lg) {
          background-color: rgba(0, 0, 0, .5);
        }
      }

      &::after {
        @media (min-width: $bp_lg) {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
}
