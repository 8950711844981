#header,
#header-sticky-wrapper {
  // sass-lint:disable-all
  z-index: 1000 !important;
  // sass-lint:enable-all
}

.offcanvas {
  transform: translateX(-100%);
  transition: transform .4s ease;
  height: calc(100% - 48px);

  // sass-lint:disable-all
  z-index: 1010 !important;
  // sass-lint:enable-all
  &.active {
    transform: translateX(0);
  }

  .main {
    max-height: calc(100% - 120px);

    // sass-lint:disable-all
    &::-webkit-scrollbar {
      @apply w-1;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      @apply bg-black-25;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      @apply bg-brand;
    }

    // sass-lint:enable-all
  }

  &__burger {
    // sass-lint:disable-all
    content: ' ';
    width: 25px;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    // sass-lint:enable-all

    &-item {
      display: block;
      position: absolute;
      border-radius: 5px;
      height: 2px;
      width: 100%;
      opacity: 1;
      transition: top .3s, bottom .3s, opacity .3s, transform .3s ease;

      &:nth-child(1) {
        top: 0;
        transform: rotate(0deg);

        .active & {
          top: 50%;
          transform: rotate(45deg);
        }
      }

      &:nth-child(2) {
        top: 50%;
        transform: translate(0, 0);

        .active & {
          transform: translate(100%, -50%);
          opacity: 0;
        }
      }

      &:nth-child(3) {
        top: 100%;
        transform: rotate(0deg);

        .active & {
          top: 50%;
          transform: rotate(-45deg);
        }
      }
    }
  }
}


// Language Select
.languageselect {
  ul li {
    + li {
      @apply border-l-2;
    }
  }
}

// search icon
.search {
  .icon-cross {
    @media (min-width: $bp_lg) {
      margin-left: 2px;
    }
  }
}

// Submenu Brands
// sass-lint:disable-all
.main__submenu {
  &--brands {

    .icon-bve,
    .icon-bionisys {
      &::before {
        @apply text-black;
      }
    }

    .icon-mack_editions {
      &::before {
        @apply text-blue;
      }
    }

    &.active {

      @media (max-width: $bp_lg - 1) {
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        height: calc(100vh - 102px); /* Fallback for browsers that do not support Custom Properties */
        height: calc((var(--vh, 1vh) * 100) - 102px);
      }

      @media (min-width: $bp_lg) {
        top: 0;
        height: auto;
      }
    }
  }
}

// sass-lint:enable-all
