.benefit__wrapper {
  @media (min-width: $bp_lg) {
    width: calc(25% - 1rem);
  }
}

.benefit {
  opacity: 0;
  transition: opacity .3s .3s ease-in-out;

  &.resized {
    opacity: 1;
  }
}

