// sass-lint:disable-all

@font-face {
  font-family: 'VinSansPro';
  font-style: normal;
  font-weight: 400;
  src: url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-regular.woff') format('woff'), /* Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-regular.svg#VinSansPro-Regular') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'VinSansPro';
  font-style: normal;
  font-weight: 500;
  src: url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-medium.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-medium.woff') format('woff'), /* Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-medium.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-medium.svg#VinSansPro-Medium') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'VinSansPro';
  font-style: normal;
  font-weight: 600;
  src: url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-semibold.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-semibold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-semibold.woff') format('woff'), /* Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-semibold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-semibold.svg#VinSansPro-SemiBold') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'VinSansPro';
  font-style: normal;
  font-weight: 700;
  src: url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-bold.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-bold.woff') format('woff'), /* Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-bold.svg#VinSansPro-Bold') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'VinSansPro';
  font-style: italic;
  font-weight: 400;
  src: url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-light.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-light.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-light.woff') format('woff'), /* Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-light.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-light.svg#VinSansPro-Light') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'VinSansPro';
  font-style: italic;
  font-weight: 500;
  src: url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-mediumitalic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-mediumitalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-mediumitalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-mediumitalic.woff') format('woff'), /* Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-mediumitalic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-mediumitalic.svg#VinSansPro-MediumItalic') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'VinSansPro';
  font-style: italic;
  font-weight: 600;
  src: url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-semibolditalic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-semibolditalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-semibolditalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-semibolditalic.woff') format('woff'), /* Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-semibolditalic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-semibolditalic.svg#VinSansPro-SemiBoldItalic') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'VinSansPro';
  font-style: italic;
  font-weight: 700;
  src: url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-bolditalic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-bolditalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-bolditalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-bolditalic.woff') format('woff'), /* Modern Browsers */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-bolditalic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../hundertprozentgenuss/webfonts/vinsanspro/mint-type-vinsanspro-bolditalic.svg#VinSansPro-BoldItalic') format('svg'); /* Legacy iOS */
}

// sass-lint:enable-all
