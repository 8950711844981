.component {
    max-width: 1920px;
    @apply mx-auto;
}

a[href^="#"] {
    text-decoration: underline;
    text-decoration-style: dotted;
}

iframe:not([src]) {
    display: none;
}

.cookiefirst-disabled-resource {
    text-align: center;

    button {
        text-decoration: underline;

        &:hover, &:focus {
            text-decoration: none;
        }
    }
}
