#content {
  ul.pagination {
    li {
      padding-left: 0;

      &::before {
        content: '';
      }

      a {
        word-spacing: 100vw;
      }
    }
  }

  .navigation-prev-next {
    ul.pagination {
      li {
        a {
          word-spacing: initial;
        }
      }
    }
  }
}
