// sass-lint:disable-all
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:focus {
    -webkit-tap-highlight-color: rgba(transparent, 0);
    outline: none;
  }
}

// sass-lint:enable-all

a:hover {
  @apply cursor-pointer;
}

.button {
  @apply underline text-lg;
}

// Hide images during lazy loading if not loaded
img:not([src], [data-src]) {
  color: transparent;
  display: none;
}

.mack-container {
  max-width: $bp_xxl;
}

.text-content {
  a {
    @apply underline;
    &:hover {
      @apply no-underline;
    }
  }
}

iframe:not([src]) {
  display: none;
}

.cookiefirst-disabled-resource {
  text-align: center;

  button {
    text-decoration: underline;

    &:hover, &:focus {
      text-decoration: none;
    }
  }
}

.fill-current {
  fill: currentColor;
}
