h1, .h1 {
  @apply font-headline;
  text-transform: uppercase;
  font-size: calc(3.75rem * .5);
  line-height: calc(3.75rem * .6);
  margin-bottom: 1rem;
  @media (min-width: $bp_md) {
    margin-bottom: 1.5rem;
    font-size: calc(3.75rem * .7);
    line-height: calc(3.75rem * .8);
  }
  @media (min-width: $bp_lg) {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }
}

h2, .h2 {
  @extend h1;
  font-size: calc(2.815rem * .5);
  line-height: calc(2.815rem * .6);
  @media (min-width: $bp_md) {
    font-size: calc(2.815rem * .7);
    line-height: calc(2.815rem * .8);
  }
  @media (min-width: $bp_lg) {
    font-size: 2.815rem;
    line-height: 2.815rem;
  }
}

h3, .h3 {
  @extend h1;
  font-size: 1.25rem;
  line-height: 1.5rem;
  @media (min-width: $bp_md) {
    font-size: calc(1.875rem * .7);
    line-height: calc(1.875rem * .8);
  }
  @media (min-width: $bp_lg) {
    font-size: 1.875rem;
    line-height: 1.875rem;
  }
}

h4, .h4 {
  @extend h1;
  font-size: 1rem;
  line-height: 1.125rem;
  @media (min-width: $bp_lg) {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
}

h5, h6, .h5, .h6 {
  @extend h4;
}

*:not(div) {
  + h1, + h2, + h3, + h4, + h5, + h6 {
    @apply mt-6;
    @media (min-width: $bp_lg) {
      @apply mt-8;
    }
  }
}

// Paragraphs
p {
  @apply mb-4;
  .success-message & {
    @apply mb-0;
  }
}

// List Styles
#content {
  ul li {
    padding-left: 1.05rem;
    position: relative;

    &::before {
      @include iconfont($icon-circle);
      position: absolute;
      left: 0;
      font-size: .7rem;
    }
  }

  ol li {
    list-style: decimal;
    margin-left: 1.05rem;
  }
}


#content > *:first-child {
  &.component.headline {
    @apply mt-4;
    @media (min-width: $bp_md) {
      @apply mt-6;
    }
    @media (min-width: $bp_lg) {
      @apply mt-8;
    }
  }
}


#content table {
  tr {
    border-bottom: 1px solid $c-black;

    th, td {
      min-width: 105px;
      font-size: 1rem;
      padding: 4px 0;
    }
  }
}
