.overlay {
  height: 100%;
  transition: opacity .3s ease-in-out;
}

.jquery-modal {
  z-index: 1000;
}

#modal_b2b {
  width: 100%;
  max-width: 500px;
}
