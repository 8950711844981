.videoslider {
  .slick-slider {
    margin: 0 -1rem;
  }

  .slick-slide {
    margin-right: .5rem;
    margin-left: .5rem;
  }
}

.loading {
  padding-bottom: 56.25%;
  background: linear-gradient(-90deg, #eeeeee, #ffffff, #eeeeee);
  background-size: 300% 300%;
  animation: gradient 3s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
