// sass-lint:disable-all

.slick-slider,
.slick {
    display: none;
    &.slick-initialized {
       display: block;
    }
    &.slick-initialized.flex {
        display: inherit;
        flex-wrap: wrap;
    }
}

.slick-dots {
    margin: 1rem 0 .5rem;
    width: 100%;

    li {
        position: relative;
        height: auto;
        width: auto;
        list-style: none;
        padding-left: .5rem !important;

        &::before {
            display: none;
        }

        &.slick-active {
            a::before {
                transform: scale(1);
                transition-delay: .2s;
                transition-timing-function: ease-out;
            }
        }

        a {
            display: block;
            position: relative;
            height: .75rem;
            width: .75rem;
            font-size: 0;
            color: transparent;
            border: 2px solid black;
            border-radius: 50%;
            overflow: hidden;

            &::before {
                content: ' ';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                border: 0;
                height: 100%;
                width: 100%;
                background: black;
                transition: transform .2s ease-in;
                transform: scale(0);
                border-radius: 50%;
            }
        }
    }
}

.slick-slide:not(:first-child) {
    display: none;

    .slick-initialized & {
        display: block;
    }
}

// sass-lint:enable-all
