@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?43u685') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?43u685') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?43u685') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?43u685##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bve {
  &:before {
    content: $icon-bve; 
  }
}
.icon-bionisys {
  &:before {
    content: $icon-bionisys; 
  }
}
.icon-imparatowein .path1 {
  &:before {
    content: $icon-imparatowein-path1;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path2 {
  &:before {
    content: $icon-imparatowein-path2;  
    margin-left: -9.939453125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path3 {
  &:before {
    content: $icon-imparatowein-path3;  
    margin-left: -9.939453125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path4 {
  &:before {
    content: $icon-imparatowein-path4;  
    margin-left: -9.939453125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path5 {
  &:before {
    content: $icon-imparatowein-path5;  
    margin-left: -9.939453125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path6 {
  &:before {
    content: $icon-imparatowein-path6;  
    margin-left: -9.939453125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path7 {
  &:before {
    content: $icon-imparatowein-path7;  
    margin-left: -9.939453125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path8 {
  &:before {
    content: $icon-imparatowein-path8;  
    margin-left: -9.939453125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path9 {
  &:before {
    content: $icon-imparatowein-path9;  
    margin-left: -9.939453125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path10 {
  &:before {
    content: $icon-imparatowein-path10;  
    margin-left: -9.939453125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path11 {
  &:before {
    content: $icon-imparatowein-path11;  
    margin-left: -9.939453125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path12 {
  &:before {
    content: $icon-imparatowein-path12;  
    margin-left: -9.939453125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path13 {
  &:before {
    content: $icon-imparatowein-path13;  
    margin-left: -9.939453125em;  
    color: rgb(0, 149, 0);
  }
}
.icon-imparatowein .path14 {
  &:before {
    content: $icon-imparatowein-path14;  
    margin-left: -9.939453125em;  
    color: rgb(255, 255, 255);
  }
}
.icon-imparatowein .path15 {
  &:before {
    content: $icon-imparatowein-path15;  
    margin-left: -9.939453125em;  
    color: rgb(255, 0, 0);
  }
}
.icon-mack_editions {
  &:before {
    content: $icon-mack_editions; 
  }
}
.icon-mack_wines {
  &:before {
    content: $icon-mack_wines; 
  }
}
.icon-mack_schuehle {
  &:before {
    content: $icon-mack_schuehle; 
  }
}
.icon-mack_schuehle_claim {
  &:before {
    content: $icon-mack_schuehle_claim; 
  }
}
.icon-mack_spirits {
  &:before {
    content: $icon-mack_spirits; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-unlock {
  &:before {
    content: $icon-unlock; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-circle-add {
  &:before {
    content: $icon-circle-add; 
  }
}
.icon-inbox {
  &:before {
    content: $icon-inbox; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-circle {
  &:before {
    content: $icon-circle; 
  }
}
.icon-glasses {
  &:before {
    content: $icon-glasses; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-paper-clip {
  &:before {
    content: $icon-paper-clip; 
  }
}
.icon-ms-logo-circle .path1 {
  &:before {
    content: $icon-ms-logo-circle-path1;  
    color: rgb(35, 58, 122);
  }
}
.icon-ms-logo-circle .path2 {
  &:before {
    content: $icon-ms-logo-circle-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-ms-logo {
  &:before {
    content: $icon-ms-logo; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-heart {
  &:before {
    content: $icon-heart; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-toggle {
  &:before {
    content: $icon-toggle; 
  }
}
.icon-repeat {
  &:before {
    content: $icon-repeat; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-paper {
  &:before {
    content: $icon-paper; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-cross {
  &:before {
    content: $icon-cross; 
  }
}
.icon-file {
  &:before {
    content: $icon-file; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-crop_free {
  &:before {
    content: $icon-crop_free; 
  }
}

