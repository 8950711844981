// sass-lint:disable-all
// IE 11 FIX
// https://github.com/michalsnik/aos/issues/244
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  [data-aos^='fade'][data-aos^='fade'], [data-aos^='zoom'][data-aos^='zoom'] {
    opacity: 1;
  }
}

// sass-lint:enable-all
