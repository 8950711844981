// sass-lint:disable-all
// Web-Icons
@import '../../base/webfonts/mackicons/style';

// Fonts
@font-face {
  font-family: 'Brandontxtreg';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('../../base/webfonts/BrandonText_Regular/Brandontxtreg.eot'); /* IE9 Compat Modes */
  src: url('../../base/webfonts/BrandonText_Regular/Brandontxtreg.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../base/webfonts/BrandonText_Regular/Brandontxtreg.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../base/webfonts/BrandonText_Regular/Brandontxtreg.woff') format('woff'), /* Pretty Modern Browsers */
  url('../../base/webfonts/BrandonText_Regular/Brandontxtreg.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../base/webfonts/BrandonText_Regular/Brandontxtreg.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Brandontxtregit';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('../../base/webfonts/BrandonText_RegularItalic/Brandontxtregit.eot'); /* IE9 Compat Modes */
  src: url('../../base/webfonts/BrandonText_RegularItalic/Brandontxtregit.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../base/webfonts/BrandonText_RegularItalic/Brandontxtregit.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../base/webfonts/BrandonText_RegularItalic/Brandontxtregit.woff') format('woff'), /* Pretty Modern Browsers */
  url('../../base/webfonts/BrandonText_RegularItalic/Brandontxtregit.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../base/webfonts/BrandonText_RegularItalic/Brandontxtregit.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Brandontxtbld';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('../../base/webfonts/BrandonText_Bold/Brandontxtbld.eot'); /* IE9 Compat Modes */
  src: url('../../base/webfonts/BrandonText_Bold/Brandontxtbld.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../base/webfonts/BrandonText_Bold/Brandontxtbld.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../base/webfonts/BrandonText_Bold/Brandontxtbld.woff') format('woff'), /* Pretty Modern Browsers */
  url('../../base/webfonts/BrandonText_Bold/Brandontxtbld.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../base/webfonts/BrandonText_Bold/Brandontxtbld.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Brandontxtbldit';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('../../base/webfonts/BrandonText_BoldItalic/Brandontxtbldit.eot'); /* IE9 Compat Modes */
  src: url('../../base/webfonts/BrandonText_BoldItalic/Brandontxtbldit.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../base/webfonts/BrandonText_BoldItalic/Brandontxtbldit.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../base/webfonts/BrandonText_BoldItalic/Brandontxtbldit.woff') format('woff'), /* Pretty Modern Browsers */
  url('../../base/webfonts/BrandonText_BoldItalic/Brandontxtbldit.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../base/webfonts/BrandonText_BoldItalic/Brandontxtbldit.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'MSInterstateHeadline';
  font-weight: bold;
  font-display: swap;
  src: url('../../base/webfonts/MSInterstateHeadline/MSInterstateHeadline.eot'); /* IE9 Compat Modes */
  src: url('../../base/webfonts/MSInterstateHeadline/MSInterstateHeadline.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../base/webfonts/MSInterstateHeadline/MSInterstateHeadline.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../base/webfonts/MSInterstateHeadline/MSInterstateHeadline.woff') format('woff'), /* Pretty Modern Browsers */
  url('../../base/webfonts/MSInterstateHeadline/MSInterstateHeadline.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../base/webfonts/MSInterstateHeadline/MSInterstateHeadline.svg#svgFontName') format('svg'); /* Legacy iOS */
}

// sass-lint:enable-all
