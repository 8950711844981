.component.brands {
  .subline {
    p {
      margin-bottom: 0;
    }

    p + p {
      margin-top: .5rem;
    }
  }
}